import { InputLabel, Modal, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import UPButton from 'components/button';

export const AddNewDocumentButton = styled(UPButton)(({ theme }) => ({
  margin: '26px 16px 16px 0px',
  height: '15%',
  minWidth: '86px',
  top: '3px',
  [theme.breakpoints.down(690)]: {
    width: '89%',
    alignSelf: 'center',
    margin: '16px 16px 16px 24px',
  },
}));

export const ModalWrapper = styled(Modal)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '944px',
  maxHeight: '650px',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.down(1200)]: {
    paddingLeft: '70px',
    width: '90%',
  },

  [theme.breakpoints.down(1000)]: {
    width: '99%',
    maxHeight: '99%',
    height: '99%',
    paddingLeft: '0px',
  },
}));

export const ModalHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  padding: '32px 32px 0px 32px',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  background: 'rgb(250, 250, 250)',
  borderRadius: '8px',
  border: 'none',
  display: 'flex',
  flexFlow: 'column',
  [theme.breakpoints.down(1000)]: {
    height: '70%',
    width: '90%',
    top: '13.5%',
    left: '5%',
    position: 'relative',
    overflow: 'hidden',
  },
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'left',
  [theme.breakpoints.down(1000)]: {
    margin: '0px',
  },
}));

export const ModalFooter = styled(Box)(({ theme }) => ({
  flex: '0 1 40px',
  backgroundColor: 'white',
  padding: '12px 0px',
  display: 'flex',
  justifyContent: 'center',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
}));

export const ModalCloseIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 32,
  top: 32,
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const FiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down(690)]: {
    flexDirection: 'column',
    width: 'unset',
    alignItems: 'start',
  },
}));

export const FilterItem = styled(Box)(({ theme }) => ({
  width: '300px',
  display: 'inline-block',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
  },
}));

export const InvoiceFilterItem = styled(Box)(({ theme }) => ({
  width: '300px',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down(722)]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ModalRowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ModalRowDetailsContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  maxHeight: '500px',
  overflowY: 'scroll',
  [theme.breakpoints.down(1000)]: {
    maxHeight: 'inherit',
  },
  [theme.breakpoints.down(722)]: {
    padding: '10px 15px 58px 12px',
  },
}));

export const DocumentError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
}));

export const NewDocumentRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '5px 32px',
  justifyContent: 'space-between',
  [theme.breakpoints.down(900)]: {
    width: '98%',
    marginLeft: '10px',
  },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  width: '96%',
  padding: '10px',
  border: '1px dotted black',
  borderRadius: '5px',
  [theme.breakpoints.down(900)]: {
    width: '98%',
  },
}));

export const DeleteColumn = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignSelf: 'center',
}));

export const FormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  marginBottom: '5px',
  [theme.breakpoints.down(900)]: {
    flexDirection: 'column',
  },
}));

export const FileNameDisplayArea = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  paddingTop: '17px',
  width: '30%',
  [theme.breakpoints.down(900)]: {
    width: '50%',
  },
}));

export const LargeFormField = styled(Box)(({ theme }) => ({
  width: '30%',
  [theme.breakpoints.down(900)]: {
    width: '50%',
  },
  [theme.breakpoints.down(525)]: {
    width: '100%',
  },
}));

export const SmallFormField = styled(Box)(({ theme }) => ({
  width: '20%',
  [theme.breakpoints.down(900)]: {
    width: '100%',
  },
}));

export const ChooseCategoryContainer = styled(Box)(({ theme }) => ({
  width: '30%',
  padding: '10px 32px',
  [theme.breakpoints.down(900)]: {
    width: '50%',
    padding: '0px 20px 0px 10px',
  },
  [theme.breakpoints.down(525)]: {
    width: '100%',
  },
}));

export const MobileViewGridContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'disableScroll',
})<{ disableScroll?: boolean }>(({ disableScroll }) => ({
  ...(disableScroll ? { overflow: 'hidden', position: 'fixed', width: '83%' } : { width: '95%' }),
  marginTop: '1em',
  marginLeft: '0.5em',
  borderRadius: '8px',
  paddingLeft: '16px',
}));

export const GeneralViewGridContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'disableScroll',
})<{ disableScroll?: boolean }>(({ disableScroll }) => ({
  ...(disableScroll ? { '.MuiDataGrid-virtualScroller': { overflow: 'hidden' } } : {}),
  height: '100vh',
  width: '100%',
}));
