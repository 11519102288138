import { isNaN } from 'lodash';
import moment from 'moment';
import { treatAsUTC } from 'utils/helpers';
import { IDocumentFormFields } from 'types/documents/IDocumentFormFields';
import { Document, DocumentCategories, DocumentToSend } from './types';

export const fromDocumentToDocumentToSend = (documents: Document[]): DocumentToSend[] => {
  const formattedModels: DocumentToSend[] = [];
  documents.forEach(document => {
    formattedModels.push({
      file: document.file,
      category: document.type.toString(),
      periodStart: moment(new Date(document.periodStart)).format('DD/MM/YYYY'),
      periodEnd: moment(new Date(document.periodEnd)).format('DD/MM/YYYY'),
      employeeId: document.employeeId ? document.employeeId.toString() : '',
    });
  });
  return formattedModels;
};

export const fromDocumentFormToDocumentToSend = (documents: IDocumentFormFields[]): DocumentToSend[] => {
  const formattedModels: DocumentToSend[] = [];
  documents.forEach(document => {
    formattedModels.push({
      ...document,
      category: document.type.toString(),
      periodStart: moment(new Date(document.periodStart)).format('DD/MM/YYYY'),
      periodEnd: moment(new Date(document.periodEnd)).format('DD/MM/YYYY'),
      employeeId: document.employeeId ? document.employeeId.toString() : '',
    });
  });
  return formattedModels;
};

export const formatDateToString = (date: Date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const isDateValid = (d: any) => {
  return !isNaN(Date.parse(d));
};

export const needsPeriod = (type: number): boolean => {
  const categoriesWithValabilityDates = [
    DocumentCategories.ID,
    DocumentCategories.STUDENT_INSCRIPTION_PROOF,
    DocumentCategories.VCA,
    DocumentCategories.MEDICAL_CHECK,
    DocumentCategories.SAFETY_PASSPORT,
    DocumentCategories.DRIVING_LICENCE,
    DocumentCategories.WORK_PERMIT,
  ];
  return categoriesWithValabilityDates.map(categoryId => Number(categoryId)).includes(type);
};

export const needsEmployeedropdown = (type: number): boolean => {
  const categoriesWithEmployeeDropdown = [
    DocumentCategories.ID,
    DocumentCategories.WORK_POST_FILE,
    DocumentCategories.STUDENT_INSCRIPTION_PROOF,
    DocumentCategories.VCA,
    DocumentCategories.MEDICAL_CHECK,
    DocumentCategories.SAFETY_PASSPORT,
    DocumentCategories.BANK_CARD,
    DocumentCategories.DRIVING_LICENCE,
    DocumentCategories.WORK_PERMIT,
  ];
  return categoriesWithEmployeeDropdown.map(categoryId => Number(categoryId)).includes(type);
};

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d);
};

export const isPeriodValid = (
  startDate: Date,
  endDate: Date,
  docType: string,
): { value: boolean; errorType: string } => {
  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    return { value: false, errorType: 'invalidPeriod' };
  }
  if (startDate && endDate) {
    const noErrorType = '';
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const difference = (treatAsUTC(endDate).getTime() - treatAsUTC(startDate).getTime()) / millisecondsPerDay;
    const isInChronologicalOrder = difference >= 0;

    if (docType === DocumentCategories.ID) {
      const currentTime = new Date();
      const startDateString = typeof startDate === 'string' ? startDate : startDate.toDateString();
      const endDateString = typeof endDate === 'string' ? endDate : endDate.toDateString();
      const isValidPeriodForId = startDate < endDate && endDate > currentTime && startDateString !== endDateString;
      return { value: isValidPeriodForId, errorType: isValidPeriodForId ? noErrorType : 'invalidExpirationDate' };
    }

    if (docType !== DocumentCategories.STUDENT_INSCRIPTION_PROOF) {
      return { value: isInChronologicalOrder, errorType: isInChronologicalOrder ? noErrorType : 'invalidPeriod' };
    }

    const numberOfDaysInAYear = 365;
    const isMaximumOneYear = difference <= numberOfDaysInAYear;
    const validationForStudentInscription = isInChronologicalOrder && isMaximumOneYear;
    return {
      value: validationForStudentInscription,
      errorType: validationForStudentInscription ? noErrorType : 'invalidAcademicPeriod',
    };
  }
  return { value: false, errorType: 'required' };
};
