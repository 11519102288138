/* eslint-disable react/jsx-props-no-spreading */
import { useDropzone } from 'react-dropzone';
import { BrowseDocumentsButton } from 'components/button/styles';
import { useState } from 'react';
import { DragAndDropParagraph, DropArea, FileName } from './style';
import { UPDropzoneProps } from './types';

const UPDropzone = (props: UPDropzoneProps): JSX.Element => {
  const { onChange, instructionText, buttonText, noDrag, disabled } = props;
  const [name, setName] = useState<string>();

  const getDocumentName = (n: string): string => {
    if (n.length > 39) {
      return `${n.substring(0, 39)}...`;
    }
    return n;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noKeyboard: true,
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    },
    noDrag,
    disabled,
    multiple: false,
    onDrop: acceptedFiles => {
      onChange(acceptedFiles[0]);
      if (acceptedFiles.length > 0) {
        setName(getDocumentName(acceptedFiles[0].name));
      }
    },
    preventDropOnDocument: false,
    maxFiles: 1,
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} />
      {!name && <DragAndDropParagraph>{instructionText}</DragAndDropParagraph>}
      <FileName>{name}</FileName>
      <BrowseDocumentsButton
        onClick={e => {
          e.stopPropagation();
          open();
        }}
        variant="outlined"
      >
        {buttonText}
      </BrowseDocumentsButton>
    </DropArea>
  );
};

UPDropzone.defaultProps = {
  noDrag: false,
  disabled: false,
};
export default UPDropzone;
