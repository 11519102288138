import { useTranslation } from 'react-i18next';
import { profileSelector } from 'store/selectors/profile-selectors';
import { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import UPDatepicker from 'components/datepicker';
import UPFileInput from 'components/file-input';
import UPInput from 'components/input';
import { Box } from '@mui/system';
import {
  DOCUMENTS__SELECT_CATEGORY,
  DOCUMENTS__CATEGORY,
  DOCUMENTS__PERIOD_END,
  DOCUMENTS__PERIOD_START,
  GENERAL__SELECT,
  MENU__EMPLOYEES,
  EMPLOYEES__UPLOAD_ID_FRONT,
  EMPLOYEES__UPLOAD_ID_BACK,
  EMPLOYEES__ID_CARD_NUMBER,
  DOCUMENTS__ALL_FIELDS_ARE_REQUIRED_ERROR,
  DOCUMENTS__INVALID_PERIOD,
} from 'translations/constants';
import { useAppSelector } from 'store';
import { CompanyRole } from 'constants/CompanyRole';
import theme from 'theme';
import { getInputErrorText } from 'utils/helpers';
import { needsEmployeedropdown } from '../helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  NewDocumentRowContainer,
  FormContainer,
  FormRow,
  LargeFormField,
  DeleteColumn,
  SmallFormField,
  DocumentError,
} from '../styles';
import { AddNewRowProps } from '../types';

const IdTemplate = (props: AddNewRowProps) => {
  const {
    control,
    index,
    documentTypes,
    onDeleteRow,
    getValues,
    errors,
    companyEmployees,
    clearErrors,
    setValue,
    onChangeType,
  } = props;
  const [t] = useTranslation();
  const isEmployee = useAppSelector(profileSelector).role === CompanyRole.EMPLOYEE;
  const [withEmployeeDropdown, setWithEmployeeDropdown] = useState<boolean>(false);

  useEffect(() => {
    const documentType = Number(getValues(`documents.${index}.type`));
    setWithEmployeeDropdown(needsEmployeedropdown(documentType));
  }, [getValues, index]);

  function formatAndSaveValue(value: React.ChangeEvent<HTMLInputElement>, input: string): string {
    if (!value.target.files || value.target.files[0] === undefined || value.target.files[0] === null) {
      return '';
    }
    if (input === 'id-upload') {
      setValue(`documents.${index}.idUpload`, value.target.files[0]);
    } else if (input === 'id-upload-back') {
      setValue(`documents.${index}.idUploadBack`, value.target.files[0]);
    }
    return value.target.files[0]?.name;
  }
  return (
    <NewDocumentRowContainer>
      <FormContainer>
        <FormRow>
          <LargeFormField>
            <Controller
              name={`documents.${index}.type`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDropdownWithSearch
                  value={field.value}
                  onChange={value => {
                    onChangeType(value, index);
                  }}
                  placeholder={t(DOCUMENTS__SELECT_CATEGORY)}
                  items={documentTypes}
                  label={`${t(DOCUMENTS__CATEGORY)} *`}
                  hasDefaultValue
                  error={!!errors.documents?.[index]?.type}
                  disableClearable
                />
              )}
            />
          </LargeFormField>
          {withEmployeeDropdown && !isEmployee && (
            <LargeFormField>
              <Controller
                name={`documents.${index}.employeeId`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDropdownWithSearch
                    required
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t(GENERAL__SELECT)}
                    items={companyEmployees}
                    label={t(MENU__EMPLOYEES)}
                    disableClearable
                    error={!!errors.documents?.[index]?.employeeId}
                  />
                )}
              />
            </LargeFormField>
          )}
        </FormRow>
        <FormRow>
          <SmallFormField>
            <Controller
              name={`documents.${index}.idUploadURL`}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <UPFileInput
                  value={value}
                  onChange={ev => onChange(formatAndSaveValue(ev, 'id-upload'))}
                  label={t(EMPLOYEES__UPLOAD_ID_FRONT)}
                  placeholder={t(EMPLOYEES__UPLOAD_ID_FRONT)}
                  error={errors.documents?.[index]?.idUploadURL}
                  required
                />
              )}
            />
          </SmallFormField>
          <SmallFormField>
            <Controller
              name={`documents.${index}.idUploadBackURL`}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <UPFileInput
                  value={value}
                  onChange={ev => onChange(formatAndSaveValue(ev, 'id-upload-back'))}
                  label={t(EMPLOYEES__UPLOAD_ID_BACK)}
                  placeholder={t(EMPLOYEES__UPLOAD_ID_BACK)}
                  error={!!errors.documents?.[index]?.idUploadBackURL}
                  required
                />
              )}
            />
          </SmallFormField>
          <SmallFormField>
            <Controller
              name={`documents.${index}.idCardNumber`}
              control={control}
              rules={{ required: true, maxLength: 50 }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__ID_CARD_NUMBER)}
                  placeholder={t(EMPLOYEES__ID_CARD_NUMBER)}
                  required
                  error={!!errors.documents?.[index]?.idCardNumber}
                  inputClassName="white-background"
                />
              )}
            />
          </SmallFormField>
          <SmallFormField>
            <Controller
              name={`documents.${index}.periodStart`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDatepicker
                  required
                  disableInput
                  value={field.value}
                  onChange={value => {
                    field.onChange(value);
                    clearErrors([`documents.${index}.periodStart`, `documents.${index}.periodEnd`]);
                  }}
                  label={t(DOCUMENTS__PERIOD_START)}
                  className="datepicker--white-background"
                  error={!!errors.documents?.[index]?.periodStart}
                />
              )}
            />
          </SmallFormField>
          <SmallFormField>
            <Controller
              name={`documents.${index}.periodEnd`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDatepicker
                  required
                  disableInput
                  value={field.value}
                  onChange={value => {
                    field.onChange(value);
                    clearErrors([`documents.${index}.periodStart`, `documents.${index}.periodEnd`]);
                  }}
                  label={t(DOCUMENTS__PERIOD_END)}
                  className="datepicker--white-background"
                  error={!!errors.documents?.[index]?.periodEnd}
                />
              )}
            />
          </SmallFormField>
        </FormRow>
        <Box sx={{ marginTop: '5px' }}>
          {(errors.documents?.[index]?.type?.type === 'required' ||
            errors.documents?.[index]?.employeeId?.type ||
            errors.documents?.[index]?.idUpload?.type === 'required' ||
            errors.documents?.[index]?.idUploadBack?.type === 'required' ||
            errors.documents?.[index]?.idCardNumber?.type === 'required' ||
            errors.documents?.[index]?.periodStart?.type === 'required' ||
            errors.documents?.[index]?.periodEnd?.type === 'required') && (
            <DocumentError>{t(DOCUMENTS__ALL_FIELDS_ARE_REQUIRED_ERROR)}</DocumentError>
          )}
          {errors.documents?.[index]?.periodEnd?.type === 'invalidExpirationDate' && (
            <DocumentError>{errors.documents?.[index]?.periodEnd?.message}</DocumentError>
          )}
        </Box>
      </FormContainer>
      <DeleteColumn
        onClick={() => {
          onDeleteRow(index);
        }}
      >
        <CloseIcon fill={theme.palette.secondary.contrastText} />
      </DeleteColumn>
    </NewDocumentRowContainer>
  );
};

export default IdTemplate;
