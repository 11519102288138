import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  saveEmployeeRequest,
  getAllEmployees,
  getEmployeeByIdRequest,
  updateEmployeeRequest,
  getPendingEmployees,
  getEmployeesWithWorkerclassRequest,
  getEmployeeSelfOnboardingRequest,
  getEmployeeCountRequest,
  downloadIdImageRequest,
  downloadEmployeesRequest,
  getCompanyEmployeeInvitationLinksRequest,
  deletetCompanyEmployeeInvitationLinksRequest,
  getEmployeeSelfOnboardingWithGeneralLinkRequest,
  addCompanyInvitationRequest,
  employeeInvitationTokenValidityRequest,
} from 'api/requests/employee-requests';
import { fromEmployeeModelToEmployeeRequest } from 'mappers/employee-mapper';
import {
  EMPLOYEE__SAVE_SUCCESS,
  EMPLOYEE__SAVE_ERROR,
  EMPLOYEE__SAVE,
  EMPLOYEE__SET_ALL,
  EMPLOYEE__SET_ALL_ERROR,
  EMPLOYEE__GET_ALL,
  EMPLOYEE__GET_EMPLOYEE,
  EMPLOYEE__GET_EMPLOYEE_ERROR,
  EMPLOYEE__GET_BY_ID,
  EMPLOYEE__UPDATE,
  EMPLOYEE__UPDATE_ERROR,
  EMPLOYEE__UPDATE_SUCCESS,
  EMPLOYEE__CLEAR,
  EMPLOYEE__GET_PENDING_EMPLOYEES,
  EMPLOYEE__GET_EMPLOYEES_WITH_WORKERCLASS,
  EMPLOYEE__SET_EMPLOYEES_WITH_WORKERCLASS,
  EMPLOYEE__SET_ONBOARDING_EMPLOYEE_DETAILS,
  EMPLOYEE__ONBOARDING_EMPLOYEE_DETAILS,
  EMPLOYEE__SET_ONBOARDING,
  EMPLOYEE__SET_COUNT,
  EMPLOYEE__SET_COUNT_ERROR,
  EMPLOYEE__GET_COUNT,
  EMPLOYEE__ERROR,
  EMPLOYEE__DOWNLOAD_ID_ERROR,
  EMPLOYEE__DOWNLOAD_ID,
  EMPLOYEE__GENERAL_LOADING,
  EMPLOYEE__DOWNLOAD_ALL_ERROR,
  EMPLOYEE__DOWNLOAD_ALL,
  EMPLOYEE__GET_COMPANY_INVITATION_LINK,
  EMPLOYEE__GET_COMPANY_INVITATION_LINK_ERROR,
  EMPLOYEE__SET_COMPANY_INVITATION_LINK,
  EMPLOYEE__DELETE_COMPANY_INVITATION_LINK,
  EMPLOYEE__ONBOARDING_EMPLOYEE_WITH_GENERAL_LINK,
  EMPLOYEE__ADD_COMPANY_INVITATION_LINK,
  EMPLOYEE__GET_COMPANY_INVITATION_LINK_SUCCESS,
  EMPLOYEE__INVITATION_LINK_VALIDATION,
  EMPLOYEE__CHECK_INVITATION_LINK_VALIDITY,
} from 'store/constants';
import { IAddEditEmployee, IEmployeeGridFilters, IEmployeeTotals } from 'types/employee';
import ICompanyInvitationLink from 'types/employee/ICompanyInvitationLink';
import IEmployeeOnboardingDetails from 'types/employee/IEmployeeOnboardingDetails';
import IEmployeeWithWorkerclass from 'types/employee/IEmployeeWithWorkerclass';
import logError from 'utils/log-error';

export const setEmployeeRequestsLoadingAction = createAction<boolean>(EMPLOYEE__GENERAL_LOADING);

export const saveEmployeeSuccessAction = createAction<boolean>(EMPLOYEE__SAVE_SUCCESS);
export const saveEmployeeErrorAction = createAction<boolean>(EMPLOYEE__SAVE_ERROR);
export const setEmployeeError = createAction<string>(EMPLOYEE__ERROR);

export const saveEmployeeAction = createAsyncThunk(
  EMPLOYEE__SAVE,
  async (props: { employee: IAddEditEmployee; companyId: any; invitationToken?: string }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(saveEmployeeErrorAction(false));
    thunkApi.dispatch(saveEmployeeSuccessAction(false));
    let employeeId: number;
    try {
      const employeeRequest = fromEmployeeModelToEmployeeRequest(props.employee);
      employeeId = await saveEmployeeRequest(employeeRequest, props.companyId, props.invitationToken);
      thunkApi.dispatch(saveEmployeeSuccessAction(true));
      thunkApi.dispatch(setEmployeeError(null));
    } catch (error: any) {
      let auxErrorType = 'default';
      if (error.response.data.error) {
        switch (error.response.data.error) {
          case '[EMPLOYEES] ALREADY_EXISTS_ERROR':
            auxErrorType = 'inss';
            break;
          case 'Invalid Iban.':
            auxErrorType = 'iban';
            break;
          case 'Invalid email.':
            auxErrorType = 'email';
            break;
          case '[EMPLOYEE]PERMANENT_CHANGE_VALIDATION':
            auxErrorType = 'permanent';
            break;
          default:
            auxErrorType = error.response.data.error;
            break;
        }
      }
      thunkApi.dispatch(setEmployeeError(auxErrorType));
      thunkApi.dispatch(saveEmployeeErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
    return employeeId;
  },
);

export const setEmployeesAction = createAction<any>(EMPLOYEE__SET_ALL);
export const setEmployeesErrorAction = createAction<boolean>(EMPLOYEE__SET_ALL_ERROR);

export const getAllEmployeesAction = createAsyncThunk(
  EMPLOYEE__GET_ALL,
  async (filters: IEmployeeGridFilters | any, thunkApi) => {
    thunkApi.dispatch(setEmployeesErrorAction(false));
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    try {
      const response = await getAllEmployees(filters);
      thunkApi.dispatch(setEmployeesAction(response));
    } catch (error) {
      thunkApi.dispatch(setEmployeesErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);
export const getEmployeeAction = createAction<IAddEditEmployee>(EMPLOYEE__GET_EMPLOYEE);
export const getEmployeeErrorAction = createAction<boolean>(EMPLOYEE__GET_EMPLOYEE_ERROR);

export const getEmployeeByIdAction = createAsyncThunk(
  EMPLOYEE__GET_BY_ID,
  async (props: { employeeId: number; companyId: number }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(getEmployeeErrorAction(false));
    try {
      const response = await getEmployeeByIdRequest(props.employeeId, props.companyId);
      thunkApi.dispatch(getEmployeeAction(response));
    } catch (error) {
      thunkApi.dispatch(getEmployeeErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const updateEmployeeSuccessAction = createAction<boolean>(EMPLOYEE__UPDATE_SUCCESS);
export const updateEmployeeErrorAction = createAction<boolean>(EMPLOYEE__UPDATE_ERROR);

export const updateEmployeeAction = createAsyncThunk(
  EMPLOYEE__UPDATE,
  async (props: { employee: IAddEditEmployee; companyId: number }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(updateEmployeeErrorAction(false));
    thunkApi.dispatch(updateEmployeeSuccessAction(false));
    let employeeId: number;

    try {
      const employeeRequest = fromEmployeeModelToEmployeeRequest(props.employee);
      employeeId = await updateEmployeeRequest(employeeRequest, props.companyId);
      thunkApi.dispatch(updateEmployeeSuccessAction(true));
      thunkApi.dispatch(setEmployeeError(null));
    } catch (error: any) {
      let auxErrorType = 'default';
      if (error.response.data.error) {
        switch (error.response.data.error) {
          case '[EMPLOYEES] ALREADY_EXISTS_ERROR':
            auxErrorType = 'inss';
            break;
          case 'Invalid Iban.':
            auxErrorType = 'iban';
            break;
          case 'Invalid email.':
            auxErrorType = 'email';
            break;
          case '[EMPLOYEE]PERMANENT_CHANGE_VALIDATION':
            auxErrorType = 'permanent';
            break;
          default:
            auxErrorType = error.response.data.error;
            break;
        }
      }

      thunkApi.dispatch(setEmployeeError(auxErrorType));
      thunkApi.dispatch(updateEmployeeErrorAction(true));
    }

    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
    return employeeId;
  },
);

export const clearEmployeeAction = createAction<void>(EMPLOYEE__CLEAR);

export const getPendingEmployeesAction = createAsyncThunk(
  EMPLOYEE__GET_PENDING_EMPLOYEES,
  async (props: { pageSize: number; pageIndex: number }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(setEmployeesErrorAction(false));
    try {
      const response = await getPendingEmployees({ pageSize: props.pageSize, pageIndex: props.pageIndex });
      thunkApi.dispatch(setEmployeesAction(response));
    } catch (error) {
      thunkApi.dispatch(setEmployeesErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const setEmployeesWithWorkerclass = createAction<IEmployeeWithWorkerclass[]>(
  EMPLOYEE__SET_EMPLOYEES_WITH_WORKERCLASS,
);
export const getEmployeesWithWorkerclass = createAsyncThunk(
  EMPLOYEE__GET_EMPLOYEES_WITH_WORKERCLASS,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(setEmployeesErrorAction(false));
    try {
      const response = await getEmployeesWithWorkerclassRequest(companyId);
      thunkApi.dispatch(setEmployeesWithWorkerclass(response));
    } catch (error) {
      thunkApi.dispatch(setEmployeesErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const setOnboardingEmployeeDetails = createAction<IEmployeeOnboardingDetails>(
  EMPLOYEE__SET_ONBOARDING_EMPLOYEE_DETAILS,
);
export const setOnboarding = createAction<boolean>(EMPLOYEE__SET_ONBOARDING);

export const onboardingEmployeeDetailsAction = createAsyncThunk(
  EMPLOYEE__ONBOARDING_EMPLOYEE_DETAILS,
  async (
    props: {
      companyToken: string;
      inviteToken: string;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    try {
      const response = await getEmployeeSelfOnboardingRequest(props.companyToken, props.inviteToken);
      thunkApi.dispatch(setOnboardingEmployeeDetails(response));
    } catch (error) {
      // swallow error
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const onboardingEmployeeWithGeneralLinkAction = createAsyncThunk(
  EMPLOYEE__ONBOARDING_EMPLOYEE_WITH_GENERAL_LINK,
  async (
    props: {
      inviteToken: string;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    try {
      const response = await getEmployeeSelfOnboardingWithGeneralLinkRequest(props.inviteToken);
      // all remaining business logic remains the same, to maintain the behavior
      thunkApi.dispatch(setOnboardingEmployeeDetails(response));
    } catch (error) {
      // swallow error
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const setEmployeesCountAction = createAction<IEmployeeTotals>(EMPLOYEE__SET_COUNT);
export const setEmployeesCountErrorAction = createAction<boolean>(EMPLOYEE__SET_COUNT_ERROR);

export const getEmployeesCountAction = createAsyncThunk(
  EMPLOYEE__GET_COUNT,
  async (props: { companyId: number; weekNumber: number }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(setEmployeesCountErrorAction(false));
    try {
      const response = await getEmployeeCountRequest({ companyId: props.companyId, weekNumber: props.weekNumber });
      thunkApi.dispatch(setEmployeesCountAction(response));
      thunkApi.dispatch(setEmployeesCountErrorAction(false));
    } catch (error) {
      thunkApi.dispatch(setEmployeesCountErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const downloadIdErrorAction = createAction<boolean>(EMPLOYEE__DOWNLOAD_ID_ERROR);

export const downloadIdAction = createAsyncThunk(
  EMPLOYEE__DOWNLOAD_ID,
  async (props: { companyId: number; employeeId: number; imageName: string }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(downloadIdErrorAction(false));
    try {
      const response = await downloadIdImageRequest({ ...props });

      const urlCreator = window.URL || window.webkitURL;
      const blob = new Blob([response.content], {
        type: response.contentType,
      });
      const url = urlCreator.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      thunkApi.dispatch(downloadIdErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const downloadEmployeesErrorAction = createAction<boolean>(EMPLOYEE__DOWNLOAD_ALL_ERROR);
export const downloadEmployeesAction = createAsyncThunk(
  EMPLOYEE__DOWNLOAD_ALL,
  async (filters: IEmployeeGridFilters | any, thunkApi) => {
    thunkApi.dispatch(downloadEmployeesErrorAction(false));
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    try {
      const response = await downloadEmployeesRequest(filters);

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filters.companyId}_employees.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      logError(error);
      thunkApi.dispatch(downloadEmployeesErrorAction(true));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const setCompanyEmployeeInvitationLinks = createAction<ICompanyInvitationLink[]>(
  EMPLOYEE__SET_COMPANY_INVITATION_LINK,
);
export const getCompanyEmployeeInvitationLinksError = createAction<string>(EMPLOYEE__GET_COMPANY_INVITATION_LINK_ERROR);

export const getCompanyEmployeeInvitationLinks = createAsyncThunk(
  EMPLOYEE__GET_COMPANY_INVITATION_LINK,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(getCompanyEmployeeInvitationLinksError(''));
    try {
      const response = await getCompanyEmployeeInvitationLinksRequest(companyId);
      thunkApi.dispatch(setCompanyEmployeeInvitationLinks(response));
    } catch (error: any) {
      logError(error);
      thunkApi.dispatch(getCompanyEmployeeInvitationLinksError(error.message));
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const deleteCompanyEmployeeInvitationLinks = createAsyncThunk(
  EMPLOYEE__DELETE_COMPANY_INVITATION_LINK,
  async (props: { id: number; companyId: number }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    try {
      await deletetCompanyEmployeeInvitationLinksRequest(props.id);
      thunkApi.dispatch(getCompanyEmployeeInvitationLinks(props.companyId));
    } catch (error) {
      logError(error);
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const getCompanyEmployeeInvitationLinkSuccess = createAction<boolean>(
  EMPLOYEE__GET_COMPANY_INVITATION_LINK_SUCCESS,
);

export const addCompanyEmployeeInvitationLink = createAsyncThunk(
  EMPLOYEE__ADD_COMPANY_INVITATION_LINK,
  async (data: ICompanyInvitationLink, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    thunkApi.dispatch(getCompanyEmployeeInvitationLinksError(''));
    try {
      await addCompanyInvitationRequest(data);
      thunkApi.dispatch(getCompanyEmployeeInvitationLinks(data.companyId));
      thunkApi.dispatch(getCompanyEmployeeInvitationLinkSuccess(true));
    } catch (error: any) {
      thunkApi.dispatch(getCompanyEmployeeInvitationLinksError(error.response.data));
      thunkApi.dispatch(getCompanyEmployeeInvitationLinkSuccess(false));
      logError(error);
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);

export const setInvitationLinkInvalid = createAction<boolean>(EMPLOYEE__INVITATION_LINK_VALIDATION);
export const checkEmployeeInvitationTokenValidity = createAsyncThunk(
  EMPLOYEE__CHECK_INVITATION_LINK_VALIDITY,
  async (props: { invitationToken: string; companyToken: string }, thunkApi) => {
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(true));
    try {
      const res = await employeeInvitationTokenValidityRequest(props.invitationToken, props.companyToken);
      thunkApi.dispatch(setInvitationLinkInvalid(res));
    } catch (error: any) {
      logError(error);
    }
    thunkApi.dispatch(setEmployeeRequestsLoadingAction(false));
  },
);
