import { UPDropdownItem } from 'components/dropdown';
import { toInteger, toNumber } from 'lodash';
import { Document } from 'modules/documents/types';
import { IEmployeeGridDetails } from 'types/employee';

export const generateEmployeesArray = (employees: IEmployeeGridDetails[]) => {
  const auxEmployees = employees.map((e): UPDropdownItem => {
    return { value: `${e.id}`, label: `${e.firstName} ${e.lastName}`, isDisabled: false };
  });
  return auxEmployees;
};

const generateINSSObject = (inssCode: string) => {
  const auxArray = inssCode.split('');

  const inssObj = {
    year: toNumber(auxArray[0] + auxArray[1]),
    month: toNumber(auxArray[2] + auxArray[3]),
    day: toNumber(auxArray[4] + auxArray[5]),
    genderCode: toNumber(auxArray[6] + auxArray[7] + auxArray[8]),
  };
  return inssObj;
};

export const treatAsUTC = (date: Date) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export const getDaysBetweenDates = (date1: Date, date2: Date) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const daysBetween = (treatAsUTC(date2).getTime() - treatAsUTC(date1).getTime()) / millisecondsPerDay;
  return daysBetween;
};

const generateYear = (year: string) => {
  const auxYear = year.split('');
  return toNumber(auxYear[2] + auxYear[3]);
};

const generateDateObject = (date: string) => {
  const auxDate = new Date(date);

  const dateObj = {
    year: generateYear(`${auxDate.getFullYear()}`),
    month: auxDate.getMonth() + 1,
    day: auxDate.getDate(),
  };
  return dateObj;
};

export const isINSSValid = (inss: string, gender: number, dateOfBirth: string) => {
  let isValid = false;
  const auxINSSObj = generateINSSObject(inss);
  const auxDateObj = generateDateObject(dateOfBirth);
  // if (
  //   auxINSSObj.day === auxDateObj.day &&
  //   auxINSSObj.month === auxDateObj.month &&
  //   auxDateObj.year === auxINSSObj.year
  // ) {
  if (gender === 1 && auxINSSObj.genderCode % 2 !== 0) isValid = true;
  if (gender === 2 && auxINSSObj.genderCode % 2 === 0) isValid = true;
  // }
  return isValid;
};

export const generateNumberOfPages = (itemsPerPage: number, total: number) => {
  let numberOfPages = toInteger(total / itemsPerPage);
  if (total % itemsPerPage) numberOfPages += 1;
  return numberOfPages;
};

export const generateAgeList = (minimum: number, maximum: number) => {
  const ageArray = [];
  for (let i = minimum; i <= maximum; ++i) {
    ageArray.push({ value: i, label: i });
  }
  return ageArray;
};

export const generateEmployeeDocumentForPeriodUpdate = (doc: any): Document => {
  return {
    file: null,
    id: 1,
    name: doc.name,
    type: toNumber(doc.category),
    error: {
      dateError: false,
      typeError: false,
      invalidDate: false,
    },
  };
};

export const isPersonalInfoError = (errors: any) => {
  return (
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.gender ||
    !!errors.dateOfBirth ||
    !!errors.birthplace ||
    !!errors.birthCountry ||
    !!errors.nationality ||
    !!errors.languageId ||
    !!errors.maritalStatus ||
    !!errors.inss
  );
};

export const isContactInfoError = (errors: any) => {
  return (
    !!errors.mobileNumber ||
    !!errors.emailAddress ||
    !!errors.iceMobileNumber ||
    !!errors.address?.city ||
    !!errors.address?.streetName ||
    !!errors.address?.streetNumber ||
    !!errors.address?.busNumber ||
    !!errors.address?.postalCode ||
    !!errors.address?.countryId ||
    !!errors.stayAddress?.city ||
    !!errors.stayAddress?.streetName ||
    !!errors.stayAddress?.streetNumber ||
    !!errors.stayAddress?.busNumber ||
    !!errors.stayAddress?.postalCode ||
    !!errors.stayAddress?.countryId
  );
};

export const isWorkingInfoError = (errors: any) => {
  return (
    !!errors.workerclass ||
    !!errors.reasonForEmployment ||
    !!errors.yearsOfExperience ||
    !!errors.startDate ||
    !!errors.iban ||
    !!errors.bic ||
    !!errors.idCardNumber ||
    !!errors.modeOfTransport
  );
};

export const isAccountError = (errors: any) => {
  return (
    !!errors.idUploadURL ||
    !!errors.idUploadBackURL ||
    !!errors.idPeriodEnd ||
    !!errors.idPeriodStart ||
    !!errors.idPeriodInvalid ||
    !!errors.pictureUploadURL ||
    !!errors.password ||
    !!errors.notMatchingPassword ||
    !!errors.agreeGdpr ||
    !!errors.periodInvalid
  );
};

export const isIdPeriodValid = (startDate: Date, endDate: Date, atSave: boolean) => {
  const currentTime = new Date();

  if (!endDate || !startDate) {
    return !atSave;
  }

  const startDateAsString = typeof startDate === 'string' ? startDate : startDate.toDateString();
  const endDateAsString = typeof endDate === 'string' ? endDate : endDate.toDateString();

  if (startDateAsString === endDateAsString) {
    return false;
  }

  const startDateAsDate = new Date(startDateAsString);
  const endDateAsDate = new Date(endDateAsString);
  if (startDateAsDate > endDateAsDate || endDateAsDate < currentTime) {
    return false;
  }

  return true;
};
