import { Box, InputLabel, styled } from '@mui/material';

export const UPDatepickerContainerStyled = styled(Box, { shouldForwardProp: prop => prop !== 'error' })<{
  error?: boolean;
}>(({ theme, error }) => ({
  '.MuiOutlinedInput-root': { fontSize: '14px' },
  [theme.breakpoints.down(527)]: {
    '.MuiFormControl-root': {
      width: '100%',
    },

    '.MuiTextField-root': {
      width: '100%',
    },
  },
  ...(error && {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '.MuiOutlinedInput-root': {
      color: theme.palette.error.main,
      fontSize: '14px',
      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
    },
  }),
}));

export const UPDatepickerHelperText = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
}));
