import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteDocumentRequest,
  downloadDocumentRequest,
  downloadMultipleDocumentRequest,
  getDocumentsRequest,
  syncExpressoDocumentRequest,
  updateEmployeeOnDocumentRequest,
  updateStudentDeclarationRequest,
  uploadDocumentsRequest,
} from 'api/requests/documents-requests';
import { DocumentToSend } from 'modules/documents/types';
import {
  DOCUMENTS__DELETE_DOCUMENT,
  DOCUMENTS__DELETE_DOCUMENT_ERROR,
  DOCUMENTS__DOWNLOAD_DOCUMENT,
  DOCUMENTS__DOWNLOAD_DOCUMENT_ERROR,
  DOCUMENTS__DOWNLOAD_EXPRESSO_DOCUMENT,
  DOCUMENTS__DOWNLOAD_MULTIPLE_DOCUMENT_ERROR,
  DOCUMENTS__GENERAL_LOADING,
  DOCUMENTS__GET_DOCUMENTS,
  DOCUMENTS__GET_DOCUMENTS_ERROR,
  DOCUMENTS__GET_DOCUMENTS_PAYLOAD,
  DOCUMENTS__GET_EMPLOYEE_DOCUMENTS_PAYLOAD,
  DOCUMENTS__SAVE_DOCUMENTS,
  DOCUMENTS__SAVE_DOCUMENTS_ERROR,
  DOCUMENTS__UPDATE_EMPLOYEE_ON_DOCUMENT,
  DOCUMENTS__UPDATE_STUDENT_DECLARATION,
} from 'store/constants';
import { IDocument } from 'types/documents';
import logError from 'utils/log-error';

export const setDocumentsRequestsLoadingAction = createAction<boolean>(DOCUMENTS__GENERAL_LOADING);

export const getDocumentsPayloadAction = createAction<IDocument[]>(DOCUMENTS__GET_DOCUMENTS_PAYLOAD);
export const getEmployeeDocumentsPayloadAction = createAction<IDocument[]>(DOCUMENTS__GET_EMPLOYEE_DOCUMENTS_PAYLOAD);
export const getDocumentsErrorAction = createAction<boolean>(DOCUMENTS__GET_DOCUMENTS_ERROR);

export const getDocumentsAction = createAsyncThunk(
  DOCUMENTS__GET_DOCUMENTS,
  async (
    props: {
      companyId: number;
      category: number | null;
      employeeId?: number;
      startDate: Date | null;
      endDate: Date | null;
      triggerEmployeeDocuments?: boolean;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    thunkApi.dispatch(getDocumentsErrorAction(false));
    try {
      const documents = await getDocumentsRequest({
        companyId: props?.companyId,
        category: props.category,
        employeeId: props?.employeeId,
        startDate: props.startDate,
        endDate: props.endDate,
      });
      thunkApi.dispatch(getDocumentsErrorAction(false));
      if (props.triggerEmployeeDocuments) {
        thunkApi.dispatch(getEmployeeDocumentsPayloadAction(documents));
      } else {
        thunkApi.dispatch(getDocumentsPayloadAction(documents));
      }
    } catch (error) {
      thunkApi.dispatch(getDocumentsErrorAction(true));
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);

export const saveDocumentsErrorAction = createAction<boolean>(DOCUMENTS__SAVE_DOCUMENTS_ERROR);

export const saveDocumentsAction = createAsyncThunk(
  DOCUMENTS__SAVE_DOCUMENTS,
  async (
    props: {
      companyId: number;
      documents: DocumentToSend[];
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    try {
      await uploadDocumentsRequest({
        companyId: props.companyId,
        documents: props.documents,
      });

      thunkApi.dispatch(saveDocumentsErrorAction(false));
      thunkApi.dispatch(
        getDocumentsAction({ companyId: props.companyId, category: null, startDate: null, endDate: null }),
      );
    } catch (error) {
      thunkApi.dispatch(saveDocumentsErrorAction(true));
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);

export const updateStudentDeclaraionAction = createAsyncThunk(
  DOCUMENTS__UPDATE_STUDENT_DECLARATION,
  async (
    props: {
      companyId: number;
      employeeId: number;
      academicPeriodStart: Date;
      academicPeriodEnd: Date;
    },
    thunkApi,
  ) => {
    const { companyId, employeeId, academicPeriodStart, academicPeriodEnd } = props;
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    try {
      await updateStudentDeclarationRequest({
        companyId,
        employeeId,
        startDate: academicPeriodStart,
        endDate: academicPeriodEnd,
      });
      thunkApi.dispatch(saveDocumentsErrorAction(false));
    } catch (error) {
      thunkApi.dispatch(saveDocumentsErrorAction(true));
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);

export const deleteDocumentErrorAction = createAction<boolean>(DOCUMENTS__DELETE_DOCUMENT_ERROR);

export const deleteDocumentAction = createAsyncThunk(
  DOCUMENTS__DELETE_DOCUMENT,
  async (
    props: { companyId: number; id: number; category: number | null; startDate: Date | null; endDate: Date | null },
    thunkApi,
  ) => {
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    try {
      await deleteDocumentRequest({
        companyId: props.companyId,
        id: props.id,
      });

      thunkApi.dispatch(deleteDocumentErrorAction(false));
      thunkApi.dispatch(
        getDocumentsAction({
          companyId: props.companyId,
          category: props.category,
          startDate: props.startDate,
          endDate: props.endDate,
        }),
      );
    } catch (error) {
      thunkApi.dispatch(deleteDocumentErrorAction(true));
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);

export const downloadDocumentErrorAction = createAction<boolean>(DOCUMENTS__DOWNLOAD_DOCUMENT_ERROR);

export const downloadDocumentAction = createAsyncThunk(
  DOCUMENTS__DOWNLOAD_DOCUMENT,
  async (props: { companyId: number; id: number; name: string }, thunkApi) => {
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    try {
      const response = await downloadDocumentRequest({
        companyId: props.companyId,
        id: props.id,
      });

      const url = window.URL.createObjectURL(new Blob([response.blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.name !== 'default-filename' ? response.name : props.name);
      document.body.appendChild(link);
      link.click();

      thunkApi.dispatch(downloadDocumentErrorAction(false));
    } catch (error) {
      thunkApi.dispatch(downloadDocumentErrorAction(true));
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);

export const downloadMultipleDocumentErrorAction = createAction<boolean>(DOCUMENTS__DOWNLOAD_MULTIPLE_DOCUMENT_ERROR);

export const downloadMultipleDocumentAction = createAsyncThunk(
  DOCUMENTS__DOWNLOAD_DOCUMENT,
  async (props: { companyId: number; id: number[]; zipName: string }, thunkApi) => {
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    try {
      const response = await downloadMultipleDocumentRequest({
        companyId: props.companyId,
        id: props.id,
      });

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', props.zipName);
      document.body.appendChild(link);
      link.click();

      thunkApi.dispatch(downloadMultipleDocumentErrorAction(false));
    } catch (error) {
      thunkApi.dispatch(downloadMultipleDocumentErrorAction(true));
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);

export const syncExpressoInvoicesErrorAction = createAction<boolean>(DOCUMENTS__DOWNLOAD_DOCUMENT_ERROR);

export const syncExpressoInvoicesAction = createAsyncThunk(
  DOCUMENTS__DOWNLOAD_EXPRESSO_DOCUMENT,
  async (props: { companyId: number; startDate: Date; endDate: Date; addEmployeeDocs: boolean }, thunkApi) => {
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    try {
      await syncExpressoDocumentRequest({
        companyId: props.companyId,
        startDate: props.startDate,
        endDate: props.endDate,
        addEmployeeDocs: props.addEmployeeDocs,
      });

      thunkApi.dispatch(downloadDocumentErrorAction(false));
    } catch (error) {
      thunkApi.dispatch(downloadDocumentErrorAction(true));
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);

export const updateEmployeeOnDocumentAction = createAsyncThunk(
  DOCUMENTS__UPDATE_EMPLOYEE_ON_DOCUMENT,
  async (props: { documentId: number; employeeId: number; companyId: number }, thunkApi) => {
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(true));
    try {
      await updateEmployeeOnDocumentRequest({
        documentId: props.documentId,
        employeeId: props.employeeId,
        companyId: props.companyId,
      });
    } catch (error) {
      logError(error);
    }
    thunkApi.dispatch(setDocumentsRequestsLoadingAction(false));
  },
);
