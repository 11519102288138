import { IViewDocument } from 'types/documents/IDocument';
import { toNumber } from 'lodash';
import UPTable from 'components/table/UPTable';
import { useTranslation } from 'react-i18next';
import {
  DOCUMENTS__DOCUMENT_CATEGORY,
  DOCUMENTS__DOCUMENT_NAME,
  DOCUMENTS__EMPLOYEE,
  DOCUMENTS__PERIOD_END,
  DOCUMENTS__PERIOD_START,
} from 'translations/constants';
import { CompanyRole } from 'constants/CompanyRole';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { DocumentCategories, DocumentsMobileViewProps } from './types';
import { ReactComponent as DeleteIcon } from '../../assets/icons/DeleteGrey.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/DownloadGrey.svg';
import { MobileViewGridContainer } from './styles';

const DocumentsMobileView = (props: DocumentsMobileViewProps): JSX.Element => {
  const { documents, isEmployee, onDownloadDocument, onDeleteButtonPress, modalIsOpen } = props;

  const [t] = useTranslation();

  const userRole = useAppSelector(profileSelector).role;
  const canDeleteSharedFile = userRole === CompanyRole.ADMIN || userRole === CompanyRole.COMPANY_MANAGER;

  const userCanDeleteCheck = (docId: number) => {
    if (isEmployee) return false;

    const documentType = documents.find(doc => doc.id === docId).category;
    if (documentType === Number(DocumentCategories.ID)) return false;
    const isSharedFile = documentType === Number(DocumentCategories.SHARED_FILES);
    return !isSharedFile || (isSharedFile && canDeleteSharedFile);
  };

  const renderDataForMobile = (docs: IViewDocument[]) => {
    const auxDocs = docs.map(d => {
      return [d.id, d.name, d.categoryName, d.periodStart, d.periodEnd, d.employeeName];
    });

    return auxDocs;
  };

  const data = renderDataForMobile(documents);

  const tableHeaders = [
    {
      name: 'Id',
      options: {
        display: false,
      },
    },
    {
      name: t(DOCUMENTS__DOCUMENT_NAME),
      setCellProps: () => ({ style: { maxWidth: '150px', wordWrap: 'break-word', display: 'inline-block' } }),
    },
    { name: t(DOCUMENTS__DOCUMENT_CATEGORY) },
    { name: t(DOCUMENTS__PERIOD_START) },
    { name: t(DOCUMENTS__PERIOD_END) },
    { name: t(DOCUMENTS__EMPLOYEE) },
    {
      Name: 'Actions',
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const id = toNumber(data[dataIndex][0]);
          return (
            <div>
              <DownloadIcon className="documentsIcon" onClick={() => onDownloadDocument(id)} />
              {userCanDeleteCheck(id) && (
                <DeleteIcon onClick={() => onDeleteButtonPress(id)} className="documentsIcon" />
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: 'dropdown',
    selectableRows: false,
  };

  return (
    <MobileViewGridContainer disableScroll={modalIsOpen}>
      <UPTable columns={tableHeaders} data={data} options={options} />
    </MobileViewGridContainer>
  );
};

export default DocumentsMobileView;
