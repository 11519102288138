import { UPDropdownItem } from 'components/dropdown';
import { Control, UseFormGetValues, UseFormResetField, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import ModalProps from 'types/common/ModalProps';
import { IViewDocument } from 'types/documents/IDocument';
import { IDocumentFormFields } from 'types/documents/IDocumentFormFields';

export interface AddNewDocumentModalProps extends ModalProps {
  onClose: () => void;
  categories: UPDropdownItem[];
  companyId: number;
  category: number | null;
}

export interface Document {
  file?: File;
  id: number;
  name: string;
  periodStart?: Date;
  periodEnd?: Date;
  type: number;
  employeeId?: number;
  error?: {
    dateError: boolean;
    typeError: boolean;
    invalidDate: boolean;
    periodError?: boolean;
    academicPeriodError?: boolean;
    employeeError?: boolean;
  };
}

export interface DocumentToSend {
  file: File;
  category: string;
  periodStart: string;
  periodEnd: string;
  employeeId: string;
  idUpload?: File;
  idUploadBack?: File;
  idCardNumber?: string;
}

export interface AddNewRowProps {
  index: number;
  control: Control<{ documents: IDocumentFormFields[] }>;
  documentTypes: UPDropdownItem[];
  companyEmployees: UPDropdownItem[];
  errors: any;
  clearErrors: any;
  onChangeType: any;
  onDeleteRow(index: number): void;
  setValue?: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  resetField: UseFormResetField<any>;
}

export interface DocumentGridViewProps {
  category: number | null;
  startDate: Date | null;
  endDate: Date | null;
  modalIsOpen: boolean;
}

export interface DocumentsMobileViewProps {
  documents: IViewDocument[];
  isEmployee: boolean;
  onDownloadDocument(id: number): void;
  onDeleteButtonPress(id: number): void;
  modalIsOpen: boolean;
}

export enum DocumentCategories {
  CONTRACT = '1',
  INVOICE = '2',
  ID = '3',
  WORK_POST_FILE = '4',
  BANKING_MANDATE = '5',
  STUDENT_INSCRIPTION_PROOF = '6',
  VCA = '7',
  MEDICAL_CHECK = '8',
  SAFETY_PASSPORT = '9',
  BANK_CARD = '10',
  DRIVING_LICENCE = '11',
  WORK_PERMIT = '12',
  CONFIRMATION_SHEET = '13',
  PARTNERSHIP_AGREEMENT = '14',
  SHARED_FILES = '15',
}
