import UPDatepicker from 'components/datepicker';
import { Box, useTheme } from '@mui/material';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import UPDropzone from 'components/dropzone/UPDropzone';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DOCUMENTS__SELECT_CATEGORY,
  DOCUMENTS__CATEGORY,
  DOCUMENTS__ALL_FIELDS_ARE_REQUIRED_ERROR,
  DOCUMENTS__PERIOD_END,
  DOCUMENTS__PERIOD_START,
  DOCUMENTS__INVALID_PERIOD,
  EMPLOYEE__INVALID_ACADEMIC_PERIOD,
  DOCUMENTS__BROWSE,
  DOCUMENTS__DRAG_AND_DROP_FILES,
  GENERAL__SELECT,
  MENU__EMPLOYEES,
} from 'translations/constants';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { CompanyRole } from 'constants/CompanyRole';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  ModalRowContainer,
  DocumentError,
  NewDocumentRowContainer,
  FormContainer,
  FormRow,
  DeleteColumn,
  LargeFormField,
  SmallFormField,
} from '../styles';
import { AddNewRowProps, DocumentCategories } from '../types';
import { needsEmployeedropdown } from '../helpers';

const BaseTemplate = (props: AddNewRowProps) => {
  const {
    control,
    index,
    documentTypes,
    onDeleteRow,
    getValues,
    resetField,
    errors,
    companyEmployees,
    clearErrors,
    onChangeType,
  } = props;

  const [t] = useTranslation();
  const theme = useTheme();
  const isEmployee = useAppSelector(profileSelector).role === CompanyRole.EMPLOYEE;
  const [withEmployeeDropdown, setWithEmployeeDropdown] = useState<boolean>(false);

  useEffect(() => {
    const documentType = Number(getValues(`documents.${index}.type`));
    setWithEmployeeDropdown(needsEmployeedropdown(documentType));
  }, [getValues, index]);

  function onChangeWithConditionalFormReset(field): (value: string) => void {
    return value => {
      if (Number(value) === Number(DocumentCategories.ID)) {
        onChangeType(value, index);
      } else {
        field.onChange(value);
        const shouldResetValue = !needsEmployeedropdown(Number(value));
        setWithEmployeeDropdown(needsEmployeedropdown(Number(value)));
        if (shouldResetValue) {
          resetField(`documents.${index}.employeeId`, { defaultValue: null });
        }
      }
    };
  }

  return (
    <NewDocumentRowContainer>
      <FormContainer>
        <FormRow>
          <LargeFormField>
            <Controller
              name={`documents.${index}.type`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDropdownWithSearch
                  value={field.value}
                  onChange={onChangeWithConditionalFormReset(field)}
                  placeholder={t(DOCUMENTS__SELECT_CATEGORY)}
                  items={documentTypes}
                  label={`${t(DOCUMENTS__CATEGORY)} *`}
                  hasDefaultValue
                  error={!!errors.documents?.[index]?.type}
                  disableClearable
                />
              )}
            />
          </LargeFormField>
          <SmallFormField>
            <Controller
              name={`documents.${index}.periodStart`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDatepicker
                  required
                  disableInput
                  value={field.value}
                  onChange={value => {
                    field.onChange(value);
                    clearErrors([`documents.${index}.periodStart`, `documents.${index}.periodEnd`]);
                  }}
                  label={t(DOCUMENTS__PERIOD_START)}
                  className="datepicker--white-background"
                  error={!!errors.documents?.[index]?.periodStart}
                />
              )}
            />
          </SmallFormField>
          <SmallFormField>
            <Controller
              name={`documents.${index}.periodEnd`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDatepicker
                  required
                  disableInput
                  value={field.value}
                  onChange={value => {
                    field.onChange(value);
                    clearErrors([`documents.${index}.periodStart`, `documents.${index}.periodEnd`]);
                  }}
                  label={t(DOCUMENTS__PERIOD_END)}
                  className="datepicker--white-background"
                  error={!!errors.documents?.[index]?.periodEnd}
                />
              )}
            />
          </SmallFormField>
          {withEmployeeDropdown && !isEmployee && (
            <LargeFormField>
              <Controller
                name={`documents.${index}.employeeId`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDropdownWithSearch
                    required
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t(GENERAL__SELECT)}
                    items={companyEmployees}
                    label={t(MENU__EMPLOYEES)}
                    disableClearable
                    error={!!errors.documents?.[index]?.employeeId}
                  />
                )}
              />
            </LargeFormField>
          )}
        </FormRow>
        <ModalRowContainer>
          <Controller
            name={`documents.${index}.file`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <UPDropzone
                onChange={onChange}
                instructionText={t(DOCUMENTS__DRAG_AND_DROP_FILES)}
                buttonText={t(DOCUMENTS__BROWSE)}
              />
            )}
          />
        </ModalRowContainer>
        <Box sx={{ marginTop: '5px' }}>
          {(errors.documents?.[index]?.file?.type ||
            errors.documents?.[index]?.type?.type ||
            errors.documents?.[index]?.periodEnd?.type === 'required' ||
            errors.documents?.[index]?.periodStart?.type === 'required' ||
            errors.documents?.[index]?.employeeId?.type) && (
            <DocumentError>{t(DOCUMENTS__ALL_FIELDS_ARE_REQUIRED_ERROR)}</DocumentError>
          )}
          {errors.documents?.[index]?.periodEnd?.type === 'invalidPeriod' &&
            errors.documents?.[index]?.periodStart?.type === 'invalidPeriod' && (
              <DocumentError>{t(DOCUMENTS__INVALID_PERIOD)}</DocumentError>
            )}
          {(errors.documents?.[index]?.periodEnd?.type === 'invalidAcademicPeriod' ||
            errors.documents?.[index]?.periodStart?.type === 'invalidAcademicPeriod') && (
            <DocumentError>{t(EMPLOYEE__INVALID_ACADEMIC_PERIOD)}</DocumentError>
          )}
        </Box>
      </FormContainer>
      <DeleteColumn
        onClick={() => {
          onDeleteRow(index);
        }}
      >
        <CloseIcon fill={theme.palette.secondary.contrastText} />
      </DeleteColumn>
    </NewDocumentRowContainer>
  );
};

export default BaseTemplate;
