import { Backdrop } from '@mui/material';
import { Box } from '@mui/system';
import UPButton from 'components/button';
import DeleteModal from 'components/delete-modal';
import { UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import { toNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';
import { saveDocumentsAction } from 'store/actions/documents-action';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { employeesWithWorkerclassSelector } from 'store/selectors/employee-selector';
import { IDocumentFormFields } from 'types/documents/IDocumentFormFields';
import { CompanyRole } from 'constants/CompanyRole';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  CONTRACTS__ARE_YOU_SURE,
  DOCUMENTS__ADD_DOCUMENTS,
  DOCUMENTS__ADD_NEW_DOCUMENT,
  DOCUMENTS__CATEGORY,
  DOCUMENTS__INVALID_EXPIRATION_DATE,
  DOCUMENTS__SELECT_CATEGORY,
  DOCUMENTS__SHARED_FILES,
  EMPLOYEE__INVALID_ACADEMIC_PERIOD,
  GENERAL__CANCEL_BUTTON,
} from 'translations/constants';
import BaseTemplate from './templates/BaseTemplate';
import IdTemplate from './templates/IdTemplate';
import {
  ChooseCategoryContainer,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalRowDetailsContainer,
  ModalTitle,
  ModalWrapper,
} from './styles';
import './styles.scss';
import { fromDocumentFormToDocumentToSend, isPeriodValid } from './helpers';
import { AddNewDocumentModalProps, Document, DocumentCategories } from './types';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';

const DocumentDefaultState: IDocumentFormFields = {
  type: '',
  periodStart: null,
  periodEnd: null,
  employeeId: '',
  file: null,
  idUploadURL: '',
  idUpload: null,
  idUploadBack: null,
  idUploadBackURL: '',
  idCardNumber: '',
};

const AddNewDocumentModal = (props: AddNewDocumentModalProps): JSX.Element => {
  const { onClose, companyId, categories } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const companyEmployees = useAppSelector(employeesWithWorkerclassSelector);
  const userRole = useAppSelector(profileSelector).role;
  const isEmployee = userRole === CompanyRole.EMPLOYEE;
  const [employeesDropdownItems, setEmployeeDropdownItems] = useState<UPDropdownItem[]>([]);
  const [documentType, setDocumentType] = useState<string>('');
  const [hasPassedValidation, setHasPassedValidation] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    resetField,
    formState: { errors, isValid },
  } = useForm<{ documents: IDocumentFormFields[] }>({
    mode: 'onChange',
  });
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'documents',
  });

  useEffect(() => {
    if (documentType !== '0') setDocumentType('0');
  }, [documentType]);

  useEffect(() => {
    if (companyId) dispatch(getEmployeesWithWorkerclass(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyEmployees) {
      const items: UPDropdownItem[] = [];
      companyEmployees.forEach(employee => {
        items.push({
          value: employee.id,
          label: `${employee.firstName} ${employee.lastName}`,
        });
      });
      setEmployeeDropdownItems(items);
    }
  }, [companyEmployees]);

  const onDeleteRow = (index: number) => {
    remove(index);
  };

  const onChangeType = (type: string, index: number) => {
    remove(index);
    insert(index, {
      ...DocumentDefaultState,
      type,
    });
  };

  useEffect(() => {
    let hasValidPeriod = true;
    errors.documents?.forEach(index => {
      if (errors.documents ? [index]?.length > 0 : false) hasValidPeriod = false;
    });
    setHasPassedValidation(hasValidPeriod);
  }, [errors.documents, setHasPassedValidation, getValues]);

  const onSubmit = (data: { documents: IDocumentFormFields[] }) => {
    let hasValidPeriod = true;
    data.documents.forEach((item, index) => {
      const validationResponse = isPeriodValid(item.periodStart, item.periodEnd, item.type.toString());
      if (!validationResponse.value) {
        if (item.type !== DocumentCategories.ID) {
          setError(`documents.${index}.periodStart`, {
            type: validationResponse.errorType,
            message: t(EMPLOYEE__INVALID_ACADEMIC_PERIOD),
          });
        }
        setError(`documents.${index}.periodEnd`, {
          type: validationResponse.errorType,
          message:
            validationResponse.errorType === 'invalidExpirationDate'
              ? t(DOCUMENTS__INVALID_EXPIRATION_DATE)
              : t(EMPLOYEE__INVALID_ACADEMIC_PERIOD),
        });
        hasValidPeriod = false;
      }
    });
    if (hasValidPeriod) {
      dispatch(
        saveDocumentsAction({
          companyId,
          documents: fromDocumentFormToDocumentToSend(data.documents),
        }),
      );
      setOpenConfirmModal(false);
      onClose();
    }
  };

  return (
    <Backdrop open sx={{ zIndex: '1' }}>
      <ModalWrapper open>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>{t(DOCUMENTS__ADD_NEW_DOCUMENT)}</ModalTitle>
            <ModalCloseIcon onClick={() => setOpenConfirmModal(true)}>
              <CloseIcon fill={theme.palette.secondary.contrastText} />
            </ModalCloseIcon>
          </ModalHeader>
          <ModalRowDetailsContainer>
            {fields.map((document, index) => (
              <Box key={document.id}>
                {Number(document.type) !== 3 && (
                  <BaseTemplate
                    index={index}
                    control={control}
                    documentTypes={categories}
                    onDeleteRow={onDeleteRow}
                    getValues={getValues}
                    errors={errors}
                    companyEmployees={employeesDropdownItems}
                    clearErrors={clearErrors}
                    resetField={resetField}
                    onChangeType={onChangeType}
                  />
                )}
                {Number(document.type) === 3 && (
                  <IdTemplate
                    index={index}
                    control={control}
                    documentTypes={categories}
                    onDeleteRow={onDeleteRow}
                    getValues={getValues}
                    errors={errors}
                    companyEmployees={employeesDropdownItems}
                    clearErrors={clearErrors}
                    resetField={resetField}
                    setValue={setValue}
                    onChangeType={onChangeType}
                  />
                )}
              </Box>
            ))}
            <ChooseCategoryContainer>
              <UPDropdownWithSearch
                value={documentType}
                onChange={selectedItem => {
                  append({
                    ...DocumentDefaultState,
                    type: selectedItem.toString(),
                  });
                  setDocumentType('');
                }}
                placeholder={t(DOCUMENTS__SELECT_CATEGORY)}
                items={categories}
                label={t(DOCUMENTS__CATEGORY)}
                disabled={!(isValid && hasPassedValidation)}
              />
            </ChooseCategoryContainer>
          </ModalRowDetailsContainer>
          <ModalFooter>
            <UPButton
              text={t(DOCUMENTS__ADD_DOCUMENTS)}
              onClick={() => handleSubmit(onSubmit)()}
              disabled={!(isValid && hasPassedValidation) || getValues().documents.length === 0}
            />
            <Box sx={{ marginLeft: '12px' }}>
              <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={() => setOpenConfirmModal(true)} outlined />
            </Box>
          </ModalFooter>
          {openConfirmModal && (
            <DeleteModal
              open={openConfirmModal}
              content={t(CONTRACTS__ARE_YOU_SURE)}
              onClose={() => {
                setOpenConfirmModal(false);
              }}
              onDelete={() => {
                onClose();
              }}
            />
          )}
        </ModalContainer>
      </ModalWrapper>
    </Backdrop>
  );
};

export default AddNewDocumentModal;
