import { styled, Box } from '@mui/material';

export const UPFileInputBox = styled(Box, { shouldForwardProp: prop => prop !== 'error' })<{ error?: boolean }>(
  ({ theme, error }) => ({
    height: '49px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    borderColor: theme.palette.secondary.main,
    border: '1px solid',
    borderRadius: '3px',
    paddingLeft: '14px',
    overflow: 'hidden',
    color: theme.palette.secondary.main,
    ...(error && {
      color: theme.palette.error.main,
    }),
  }),
);

export const UPFileInputStyled = styled('input')(() => ({
  opacity: 0,
  zIndex: 999,
  position: 'absolute',
  height: '100%',
  width: '100%',
  cursor: 'pointer',
}));

export const UIFileInputAttachmentIconContainer = styled(Box)(() => ({
  cursor: 'pointer',
  width: '24px',
  height: '24px',
  marginRight: '8px',
}));

export const UIFileInputFileName = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
