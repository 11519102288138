import { toNumber } from 'lodash';
import { ICompanyAddress } from 'types/company';
import { IAddEditEmployee, IEmployeeDependants } from 'types/employee';
import IDependantsRequest from 'types/employee/IDependantsRequest';
import IEmployeeRequest from 'types/employee/IEmployeeRequest';

export const fromDependantsModelToDependantsRequest = (dependants: IEmployeeDependants): IDependantsRequest => {
  const employeeDependants: IDependantsRequest = {
    id: dependants.id !== undefined ? dependants.id : 0,
    partner: dependants.partner !== null ? dependants.partner : false,
    disabledPartner: dependants.disabledPartner !== null ? dependants.disabledPartner : false,
    children: dependants.children === '' || dependants.children === null ? 0 : toNumber(dependants.children),
    disabledChildren:
      dependants.disabledChildren === '' || dependants.disabledChildren === null
        ? 0
        : toNumber(dependants.disabledChildren),
    elderly: dependants.elderly === '' || dependants.elderly === null ? 0 : toNumber(dependants.elderly),
  };
  return employeeDependants;
};

export const getIdDateString = (employee: IAddEditEmployee, isStartDate: boolean): string => {
  const idDate = isStartDate ? employee.idPeriodStart : employee.idPeriodEnd;
  if (employee.permanent && idDate.toString().length === 0) {
    return '1/1/0001';
  }

  if (isStartDate) {
    return typeof employee.idPeriodStart === 'string' ? employee.idPeriodStart : employee.idPeriodStart.toDateString();
  }
  return typeof employee.idPeriodEnd === 'string' ? employee.idPeriodEnd : employee.idPeriodEnd.toDateString();
};

export const fromEmployeeModelToEmployeeRequest = (employee: IAddEditEmployee): IEmployeeRequest => {
  let defaultStartDate = '';

  if (employee?.startDate && typeof employee?.startDate === 'string') {
    defaultStartDate = new Date(employee.startDate).toDateString();
  }

  const employeeRequest: IEmployeeRequest = {
    id: employee.id,
    firstName: employee.firstName,
    lastName: employee.lastName,
    dateOfBirth: typeof employee.dateOfBirth === 'string' ? employee.dateOfBirth : employee.dateOfBirth.toDateString(),
    birthplace: employee.birthplace,
    birthCountry: toNumber(employee.birthCountry),
    nationality: toNumber(employee.nationality),
    inss: employee.inss,
    languageId: toNumber(employee.languageId),
    gender: toNumber(employee.gender),
    workerclass: toNumber(employee.workerclass),
    reasonForEmployment: toNumber(employee.reasonForEmployment),
    iban: employee.iban,
    bic: employee.bic,
    idCardNumber: employee.idCardNumber,
    modeOfTransport: toNumber(employee.modeOfTransport),
    maritalStatus: toNumber(employee.maritalStatus),
    dependants: employee.hasDependants ? fromDependantsModelToDependantsRequest(employee.dependants) : null,
    mobileNumber: employee.mobileNumber,
    emailAddress: employee.emailAddress,
    iceMobileNumber: employee.iceMobileNumber,
    idUpload: employee.idUpload,
    idUploadURL: employee.idUploadURL,
    idUploadBack: employee.idUploadBack,
    idUploadBackURL: employee.idUploadBackURL,
    idPeriodStart: getIdDateString(employee, true),
    idPeriodEnd: getIdDateString(employee, false),
    agreeGdpr: employee.agreeGdpr,
    letterOfIntentCheck: employee.letterOfIntentCheck,
    declarationOfTruth: employee.declarationOfTruth,
    pictureUpload: employee.pictureUpload,
    pictureUploadURL: employee.pictureUploadURL,
    password: employee.password,
    address: employee.address,
    stayAddress: employee.stayAddress?.countryId ? employee.stayAddress : null,
    yearsOfExperience: toNumber(employee.yearsOfExperience),
    status: toNumber(employee.status),
    permanent: employee.permanent,
    startDate:
      typeof employee.startDate !== 'string' && employee.startDate
        ? employee.startDate.toDateString()
        : defaultStartDate,
    inActive: employee.inActive,
    companyLocationIds: employee.companyLocationIds.map(e => {
      const val = toNumber(e?.value);
      if (Number.isNaN(val)) {
        return e;
      }
      return val;
    }),
  };

  return employeeRequest;
};

export const fromEmployeeRequestToEmployeeModel = (employee: IAddEditEmployee): IAddEditEmployee => {
  const defaultDependants: IEmployeeDependants = {
    id: 0,
    partner: false,
    disabledPartner: false,
    children: '',
    disabledChildren: '',
    elderly: '',
  };

  const defaultStayAddress: ICompanyAddress = {
    id: 0,
    streetName: '',
    streetNumber: '',
    busNumber: '',
    city: '',
    postalCode: '',
    countryId: '',
  };

  const employeeRequest: IAddEditEmployee = {
    ...employee,
    companyLocationIds: employee.companyLocationIds ? employee.companyLocationIds.map(String) : [],
    dependants: employee.hasDependants ? employee.dependants : defaultDependants,
    stayAddress: employee.hasStayAddress ? employee.stayAddress : defaultStayAddress,
  };
  return employeeRequest;
};
