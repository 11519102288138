import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const DragAndDropParagraph = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  paddingBottom: '3px',
}));

export const FileName = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 600,
  paddingBottom: '3px',
  color: '#000000',
}));

export const DropArea = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  border: '2px dotted rgba(0, 140, 21, 0.4)',
  color: '#008c15',
  width: '100%',
  fontWeight: 'bold',
}));
