import { Box, Button, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import UPSnackBar from 'components/app-container/snackbar';
import DeleteModal from 'components/delete-modal';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import FiltersMenu from 'components/filters-menu';
import { FilterButton, StyledFilterIcon } from 'components/filters-menu/styles';
import UPSearchInput from 'components/input-with-search';
import { CoefficientsPerWorkerClassOptions } from 'constants/CoefficientsPerWorkerclass';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { generateAgeFilterOptions } from 'modules/contracts/helpers';
import { PageFilter } from 'modules/contracts/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getCompaniesAsDropdownItemsAction } from 'store/actions/company-actions';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import {
  clearEmployeeAction,
  downloadEmployeesAction,
  getAllEmployeesAction,
  onboardingEmployeeDetailsAction,
  onboardingEmployeeWithGeneralLinkAction,
  saveEmployeeSuccessAction,
  setEmployeeError,
  setOnboarding,
} from 'store/actions/employee-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import { companiesDropdownItemsSelector } from 'store/selectors/company-selectors';
import {
  employeesErrorSelector,
  employeesSelector,
  numberOfEmployeesSelector,
  onboardingDetailsSelector,
  onboardingSelector,
  saveEmployeeSuccessSelector,
  updateEmployeeSuccessSelector,
} from 'store/selectors/employee-selector';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import { EmployeeStatus } from 'constants/EmployeeStatus';
import theme from 'theme';
import {
  COMPANY__COMPANY,
  CONTRACTS__ARE_YOU_SURE,
  CONTRACTS__DEFAULT_ERROR,
  EMPLOYEES__ACTIVE,
  EMPLOYEES__ADD_NEW_EMPLOYEE,
  EMPLOYEES__COMPANY_NO_EMPLOYEES,
  EMPLOYEES__EMAIL_ERROR,
  EMPLOYEES__IBAN_ERROR,
  EMPLOYEES__INSS_ERROR,
  EMPLOYEES__INVITATION_SENT_SUCCESSFULLY,
  EMPLOYEES__INVITE,
  EMPLOYEES__PERMANENT_CHANGE_VALIDATION,
  EMPLOYEES__SEARCH_EMPLOYEE_NAME,
  EMPLOYEES__SELECT_A_COMPANY,
  EMPLOYEES__SORT_ALPHABETICAL,
  EMPLOYEES__SORT_CRITERIA,
  EMPLOYEES__SORT_NEWEST,
  EMPLOYEE_STATUS__REQUIRES_ACTION,
  EMPLOYEE__COMPANY_LOCATION,
  GENERAL__AGE,
  GENERAL__ITEMS_PER_PAGE,
  GENERAL__LINK_COPIED,
  GENERAL__NAME,
  GENERAL__NO,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  GENERAL__YES,
  MENU__EMPLOYEES,
} from 'translations/constants';
import { ReactComponent as Chevron } from '../../assets/icons/Chevron.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/SortIcon.svg';
import EmployeeCard from './EmployeeCard';
import { AddEditEmployeeModal } from './add-edit-employee-modal';
import { generateNumberOfPages } from './helpers';
import InviteEmployeeModal from './invite-employee-modal';
import {
  AddEmployeeButton,
  AddInviteButtonsContainer,
  AlertStyled,
  CollapseSearchFiltersContainer,
  FiltersContainer,
  FiltersEmployeeBox,
  FiltersLeftContainer,
  FiltersRightContainer,
  NameEmployeeBox,
  SortIconBox,
  SortIconMobileBox,
  StyledDownloadIcon,
  StyledEmployeeIcon,
  StyledInviteIcon,
  UPDropdownStyled,
} from './styles';

const Employees = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyToken, inviteToken, pCompanyId, pEmployeeName } = useParams();

  const { generalToken } = useParams();
  const activeItem = useAppSelector(activeMenuItemSelector);
  const saveNewEmployeeIsSuccessful = useAppSelector(saveEmployeeSuccessSelector);
  const employees = useAppSelector(employeesSelector);
  const updateEmployeeSuccessful = useAppSelector(updateEmployeeSuccessSelector);
  const employeeError = useAppSelector(employeesErrorSelector);
  const onboardingDetails = useAppSelector(onboardingDetailsSelector);
  const userCompanyId = useAppSelector(profileSelector)?.companyId || null;
  const isAdmin = useAppSelector(profileSelector)?.role === CompanyRole.ADMIN;
  const employeeOnboarding = useAppSelector(onboardingSelector);
  const isEmployee = useAppSelector(profileSelector)?.role === CompanyRole.EMPLOYEE;
  const companiesDropdownItems = useAppSelector(companiesDropdownItemsSelector);
  const employeesNumber = useAppSelector(numberOfEmployeesSelector);
  const locationsList = useAppSelector(locationsDropdownItemsSelector);

  const [companyId, setCompanyId] = useState<string>(userCompanyId?.toString() ?? pCompanyId);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openEmployeeError, setOpenEmployeeError] = useState<boolean>(false);
  const [openAddEditModal, setOpenAddEditModal] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<number>();
  const [displayPendingEmployees, setDisplayPendingEmployees] = useState<string>('');
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState<boolean>(false);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState<boolean>(isEmployee);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [employeeCompanyId, setEmployeeCompanyId] = useState<number>(null);
  const [age, setAge] = useState<string>('');
  const [workType, setWorkType] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [active, setActive] = useState<string>('1');
  const [name, setName] = useState<string>(pEmployeeName ?? '');
  const [nameToSend, setNameToSend] = useState<string>(pEmployeeName ?? '');
  const [sortCriteria, setSortCriteria] = useState<string>('1');
  const [sortOrder, setSortOrder] = useState<number>(0);
  const [isContainerVisible, setIsContainerVisible] = useState(true);
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const [inviteSent, setInviteSent] = useState<boolean>(false);
  const [filtersPopoverAnchorEl, setFiltersPopoverAnchorEl] = useState<null | HTMLElement>(null);

  const workerclassList = useMemo(() => CoefficientsPerWorkerClassOptions(), []);
  const ageOptions = generateAgeFilterOptions();

  const employeeFilters = useMemo((): PageFilter[] => {
    const activeFilterOptions: UPDropdownItem[] = [
      {
        value: '1',
        label: t(GENERAL__YES),
      },
      {
        value: '2',
        label: t(GENERAL__NO),
      },
    ];

    const filters: PageFilter[] = [];

    const companyBasedFilters: PageFilter[] = [
      {
        label: t(EMPLOYEE__COMPANY_LOCATION),
        value: location,
        setValue: setLocation,
        items: locationsList,
        component: UPDropdownWithSearch,
      },
      {
        label: t(GENERAL__WORKERCLASS),
        value: workType,
        setValue: setWorkType,
        items: workerclassList,
        component: UPDropdown,
        canBeEmpty: true,
      },
      {
        label: t(GENERAL__AGE),
        value: age,
        setValue: setAge,
        items: ageOptions,
        component: UPDropdown,
        canBeEmpty: true,
      },
      {
        label: t(EMPLOYEES__ACTIVE),
        value: active,
        setValue: setActive,
        items: activeFilterOptions,
        component: UPDropdown,
      },
    ];

    if (companyId) {
      filters.push(...companyBasedFilters);

      if (isAdmin) {
        filters.push({
          label: t(EMPLOYEE_STATUS__REQUIRES_ACTION),
          value: displayPendingEmployees,
          setValue: setDisplayPendingEmployees,
          items: [
            {
              value: '1',
              label: t(GENERAL__YES),
            },
            {
              value: '2',
              label: t(GENERAL__NO),
            },
          ],
          component: UPDropdown,
        });
      }
    }

    return filters;
  }, [
    t,
    location,
    locationsList,
    workType,
    workerclassList,
    age,
    ageOptions,
    active,
    isAdmin,
    displayPendingEmployees,
    companyId,
  ]);

  const employeeErrorMessages = useMemo(() => {
    return {
      default: t(CONTRACTS__DEFAULT_ERROR),
      inss: t(EMPLOYEES__INSS_ERROR),
      iban: t(EMPLOYEES__IBAN_ERROR),
      email: t(EMPLOYEES__EMAIL_ERROR),
      permanent: t(EMPLOYEES__PERMANENT_CHANGE_VALIDATION),
    };
  }, [t]);

  const handleCollapseFiltersClick = () => {
    setIsContainerVisible(prevState => !prevState);
  };

  const handleSortIconClick = () => {
    setSortOrder(sortOrder === 0 ? 1 : 0);
  };

  const resetFilters = () => {
    setName('');
    setNameToSend('');
    setAge('');
    setLocation('');
    setActive('1');
    setSortOrder(0);
    setSortCriteria('1');
    setWorkType('');
    setDisplayPendingEmployees('');
  };

  const handlePageChange = (e: any, value: number) => {
    setCurrentPage(value);
  };

  const displayEmployees = useCallback(() => {
    if (isAdmin && !companyId) {
      return (
        <AlertStyled severity="warning" icon={false} variant="filled">
          {t(EMPLOYEES__SELECT_A_COMPANY)}
        </AlertStyled>
      );
    }
    if (employees.length > 0) {
      return employees.map(employee => (
        <Grid item xs={12} sm={6} md={4} lg={2} key={employee.id}>
          <EmployeeCard
            employee={employee}
            key={employee.id}
            companyId={companyId}
            setCopied={setCopiedToClipboard}
            setInviteSent={setInviteSent}
            onClickEdit={employeeId => {
              setOpenAddEditModal(true);
              setOpenEmployeeError(false);
              dispatch(setEmployeeError(null));
              setIsEdit(true);
              setSelectedEmployee(employeeId);
              if (isAdmin && displayPendingEmployees) {
                setCompanyId(employee.companyId.toString());
                setEmployeeCompanyId(employee.companyId);
              }
            }}
          />
        </Grid>
      ));
    }
    return t(EMPLOYEES__COMPANY_NO_EMPLOYEES);
  }, [isAdmin, companyId, displayPendingEmployees, employees, t, dispatch]);

  const onClickFilterButton = event => {
    setFiltersPopoverAnchorEl(event.currentTarget);
  };

  const onCloseFilterPopover = () => {
    setFiltersPopoverAnchorEl(null);
  };

  const onClearEmployeesFilters = () => {
    setLocation('');
    setWorkType('');
    setAge('');
    setActive('1');
    setDisplayPendingEmployees('');
  };

  const sortCriteriaDropdownOptions: UPDropdownItem[] = [
    { value: '1', label: t(EMPLOYEES__SORT_NEWEST) },
    { value: '2', label: t(EMPLOYEES__SORT_ALPHABETICAL) },
  ];

  const getStatus = useCallback((): number[] => {
    if (displayPendingEmployees === '1') {
      return [EmployeeStatus.RequiresAction];
    }

    if (displayPendingEmployees === '2') {
      return [EmployeeStatus.Ready, EmployeeStatus.Deployed, EmployeeStatus.Invited];
    }

    return [EmployeeStatus.Ready, EmployeeStatus.Deployed, EmployeeStatus.Invited, EmployeeStatus.RequiresAction];
  }, [displayPendingEmployees]);

  useEffect(() => {
    if (employeeError) setOpenEmployeeError(true);
  }, [employeeError]);

  useEffect(() => {
    if (companyId && !isEmployee) {
      dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId) }));
    }
  }, [companyId, isEmployee, dispatch]);

  useEffect(() => {
    if (activeItem !== t(MENU__EMPLOYEES) && !isEmployee) {
      dispatch(setMenuStatusAction(t(MENU__EMPLOYEES)));
    }
  }, [activeItem, dispatch, t, isEmployee]);

  useEffect(() => {
    if (employeesNumber) {
      const auxNumber = generateNumberOfPages(itemsPerPage, employeesNumber);
      setNumberOfPages(auxNumber);
    }
  }, [employeesNumber, itemsPerPage]);

  useEffect(() => {
    if (companyToken && inviteToken && !employeeOnboarding) {
      dispatch(setOnboarding(true));
      // dispatch(checkEmployeeInvitationTokenValidity({ invitationToken: inviteToken, companyToken }));
      dispatch(onboardingEmployeeDetailsAction({ companyToken, inviteToken }));
    }
  }, [companyToken, dispatch, employeeOnboarding, inviteToken, onboardingDetails]);

  useEffect(() => {
    if (generalToken && !employeeOnboarding) {
      dispatch(setOnboarding(true));
      dispatch(onboardingEmployeeWithGeneralLinkAction({ inviteToken: generalToken }));
    }
  }, [generalToken, dispatch, employeeOnboarding, onboardingDetails]);

  useEffect(() => {
    if (!isEmployee && !companyToken && !inviteToken && !generalToken && companyId) {
      dispatch(
        getAllEmployeesAction({
          companyId,
          pageSize: itemsPerPage,
          pageIndex: currentPage,
          workType: workType === '' ? undefined : workType,
          age,
          locationId: location === '' ? undefined : toNumber(location),
          active: toNumber(active),
          sortOrder,
          sortCriteria: toNumber(sortCriteria),
          name: nameToSend === '' ? undefined : nameToSend,
          status: getStatus(),
        }),
      );
    } else {
      // setCompanyId(undefined);
      setCurrentPage(currentPage);
    }
  }, [
    dispatch,
    companyId,
    displayPendingEmployees,
    isEmployee,
    companyToken,
    inviteToken,
    generalToken,
    itemsPerPage,
    currentPage,
    workType,
    age,
    location,
    active,
    sortOrder,
    sortCriteria,
    nameToSend,
    getStatus,
  ]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getCompaniesAsDropdownItemsAction());
    }
  }, [isAdmin, dispatch]);

  useEffect(() => {
    // if (isAdmin && displayPendingEmployees) {
    //   dispatch(getPendingEmployeesAction({ pageSize: itemsPerPage, pageIndex: currentPage }));
    // }

    if (companyId && (saveNewEmployeeIsSuccessful || updateEmployeeSuccessful)) {
      dispatch(
        getAllEmployeesAction({
          companyId,
          pageSize: itemsPerPage,
          pageIndex: currentPage,
          workType: workType === '' ? undefined : workType,
          age,
          locationId: location === '' ? undefined : toNumber(location),
          active: toNumber(active),
          name: nameToSend === '' ? undefined : nameToSend,
          status: getStatus(),
        }),
      );
      dispatch(saveEmployeeSuccessAction(false));
    }
  }, [
    dispatch,
    saveNewEmployeeIsSuccessful,
    companyId,
    updateEmployeeSuccessful,
    itemsPerPage,
    currentPage,
    isAdmin,
    displayPendingEmployees,
    workType,
    age,
    location,
    active,
    nameToSend,
    getStatus,
  ]);

  useEffect(() => {
    if (!companyId) {
      resetFilters();
    }
  }, [companyId]);

  const onDownloadEmployees = () => {
    dispatch(
      downloadEmployeesAction({
        companyId,
        pageSize: itemsPerPage,
        pageIndex: currentPage,
        workType: workType === '' ? undefined : workType,
        age: age === '' ? undefined : toNumber(age),
        locationId: location === '' ? undefined : toNumber(location),
        active: toNumber(active),
        sortOrder,
        sortCriteria: toNumber(sortCriteria),
        name: nameToSend === '' ? undefined : nameToSend,
      }),
    );
  };

  if (employeeOnboarding) {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <AddEditEmployeeModal
          open={Boolean(companyToken && inviteToken) || Boolean(generalToken)}
          onClose={() => {
            setOpenConfirmCloseModal(true);
          }}
          employeeId={null}
          companyId={toNumber(onboardingDetails?.companyId)}
        />
        {openConfirmCloseModal && (
          <DeleteModal
            open={openConfirmCloseModal}
            content={t(CONTRACTS__ARE_YOU_SURE)}
            onClose={() => {
              setOpenConfirmCloseModal(false);
            }}
            onDelete={() => {
              navigate('/auth/login');
              setOpenConfirmCloseModal(false);
              dispatch(setOnboarding(false));
            }}
          />
        )}
      </Box>
    );
  }

  if (isEmployee) {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <AddEditEmployeeModal
          open={isEmployeeModalOpen}
          onClose={() => {
            setIsEmployeeModalOpen(false);
            dispatch(setMenuStatusAction('Dashboard'));
            navigate('/dashboard');
          }}
          employeeId={0}
          companyId={0}
          isEdit
        />
      </Box>
    );
  }

  return (
    <Box>
      <CollapseSearchFiltersContainer>
        <Button
          sx={{
            transform: `rotate(${isContainerVisible ? '0deg' : '180deg'})`,
            transition: 'transform 0.2s ease-in-out',
            cursor: 'pointer',
            alignSelf: 'center',
          }}
          onClick={handleCollapseFiltersClick}
        >
          <Chevron />
        </Button>
      </CollapseSearchFiltersContainer>
      {isContainerVisible && (
        <FiltersContainer>
          <FiltersLeftContainer>
            <AddInviteButtonsContainer>
              <AddEmployeeButton
                text={
                  <Tooltip title={t(EMPLOYEES__ADD_NEW_EMPLOYEE)} placement="top">
                    <Box display="flex" alignItems="center">
                      <Typography fontSize="xx-large"> + </Typography>
                      <StyledEmployeeIcon />
                    </Box>
                  </Tooltip>
                }
                onClick={() => {
                  setOpenAddEditModal(true);
                  setOpenEmployeeError(false);
                  dispatch(setEmployeeError(null));
                }}
                disabled={isAdmin && !companyId}
              />
              <AddEmployeeButton
                text={
                  <Tooltip title={t(EMPLOYEES__INVITE)} placement="top">
                    <Box display="flex" alignItems="center">
                      <Typography fontSize="xx-large"> + </Typography>
                      <StyledInviteIcon />
                    </Box>
                  </Tooltip>
                }
                onClick={() => setOpenInviteModal(true)}
                disabled={isAdmin && !companyId}
              />
              <AddEmployeeButton
                text={
                  <Tooltip title="Excel export" placement="top">
                    <StyledDownloadIcon />
                  </Tooltip>
                }
                onClick={onDownloadEmployees}
                disabled={employees.length === 0 || (isAdmin && !companyId)}
              />
            </AddInviteButtonsContainer>
            {isAdmin && (
              <Box sx={{ width: '280px', display: 'inline-block' }}>
                <UPDropdownWithSearch
                  value={companyId}
                  onChange={selectedItem => {
                    setCompanyId(selectedItem);
                    setCurrentPage(1);
                    resetFilters();
                  }}
                  placeholder={t(GENERAL__SELECT)}
                  items={companiesDropdownItems}
                  label={t(COMPANY__COMPANY)}
                  disableClearable={false}
                />
              </Box>
            )}
            <NameEmployeeBox>
              <UPSearchInput
                label={t(GENERAL__NAME)}
                placeholder={t(EMPLOYEES__SEARCH_EMPLOYEE_NAME)}
                value={name}
                onChange={value => {
                  setName(value);
                  if (!value) setNameToSend('');
                }}
                onSearch={() => {
                  setNameToSend(name);
                }}
                disabled={!companyId}
              />
            </NameEmployeeBox>
            <FiltersEmployeeBox>
              <UPDropdownStyled
                value={sortCriteria}
                onChange={event => {
                  setSortCriteria(event.target.value);
                  setSortOrder(0);
                }}
                items={sortCriteriaDropdownOptions}
                label={t(EMPLOYEES__SORT_CRITERIA)}
                disabled={!companyId}
              />
            </FiltersEmployeeBox>
            <SortIconBox sortOrder={sortOrder} onClick={handleSortIconClick}>
              <SortIcon />
            </SortIconBox>
          </FiltersLeftContainer>
          <FiltersRightContainer>
            <FilterButton
              text={
                <Box display="flex" alignItems="center">
                  <StyledFilterIcon />
                </Box>
              }
              onClick={onClickFilterButton}
              ref={filtersPopoverAnchorEl}
              disabled={!companyId}
            />
            <FiltersMenu
              open={Boolean(filtersPopoverAnchorEl)}
              anchorEl={filtersPopoverAnchorEl}
              onClose={onCloseFilterPopover}
              onApplyFilters={onCloseFilterPopover}
              onClearFilters={onClearEmployeesFilters}
              filters={employeeFilters}
            />
            <SortIconMobileBox sortOrder={sortOrder} onClick={handleSortIconClick}>
              <SortIcon />
            </SortIconMobileBox>
          </FiltersRightContainer>
        </FiltersContainer>
      )}
      <Grid container spacing={1} width="100%" marginTop={theme.spacing(2)}>
        {displayEmployees()}
      </Grid>
      {companyId && employees.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InputLabel id="paginationLabel" sx={{ fontSize: '13px', paddingRight: '10px' }}>
              {t(GENERAL__ITEMS_PER_PAGE)}
            </InputLabel>
            <Select
              sx={{ fontSize: '13px', height: '33px' }}
              labelId="paginationLabel"
              id="select"
              value={`${itemsPerPage}`}
              onChange={e => {
                setItemsPerPage(toNumber(e.target.value));
                setCurrentPage(1);
              }}
            >
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="40">40</MenuItem>
              <MenuItem value="80">80</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
          </Box>
          <Pagination
            count={numberOfPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ paddingLeft: '10px' }}
          />
        </Box>
      )}

      {openAddEditModal && (
        <AddEditEmployeeModal
          open={openAddEditModal}
          onClose={() => {
            setOpenAddEditModal(false);
            setSelectedEmployee(null);
            if (isEdit) {
              setIsEdit(false);
              dispatch(clearEmployeeAction());
            }
          }}
          isEdit={isEdit}
          employeeId={selectedEmployee}
          companyId={toNumber(companyId)}
          pendingEmployeeCompanyId={displayPendingEmployees ? employeeCompanyId : null}
          onSavePendingEmployee={() => setEmployeeCompanyId(null)}
        />
      )}
      {openInviteModal && (
        <InviteEmployeeModal open={openInviteModal} onClose={() => setOpenInviteModal(false)} companyId={companyId} />
      )}

      {employeeError && (
        <UPSnackBar
          vertical="bottom"
          horizontal="right"
          open={openEmployeeError}
          content={
            employeeError === 'inss' ||
            employeeError === 'email' ||
            employeeError === 'iban' ||
            employeeError === 'default' ||
            employeeError === 'permanent'
              ? employeeErrorMessages[employeeError]
              : t(employeeError)
          }
          type="error"
          onClose={() => {
            setOpenEmployeeError(false);
            dispatch(setEmployeeError(null));
          }}
          index={0}
        />
      )}

      {inviteSent && (
        <UPSnackBar
          vertical="bottom"
          horizontal="right"
          open={inviteSent}
          content={t(EMPLOYEES__INVITATION_SENT_SUCCESSFULLY)}
          type="success"
          onClose={() => {
            setInviteSent(false);
          }}
          index={0}
        />
      )}

      {copiedToClipboard && (
        <UPSnackBar
          vertical="bottom"
          horizontal="right"
          open={copiedToClipboard}
          content={t(GENERAL__LINK_COPIED)}
          type="success"
          onClose={() => {
            setCopiedToClipboard(false);
          }}
          index={0}
        />
      )}
    </Box>
  );
};

export default Employees;
